.sliding-words-wrapper {
    vertical-align: top;
    display: inline-block;
    overflow: hidden;
    /* set height to fit one item */
    height: 35px;
  }
  
  .sliding-words {
    display: inline-flex;
    flex-direction: column;
    position: relative;
    top: 0;
    animation: slide-words 7s linear 0ms infinite;
  }
  .word-border {
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #d6ac5b;
    // text-shadow: 0 0 3px #355379, 0 0 5px #355379
}
  @keyframes slide-words {
    from {
      top: 0;
    }
    to {
      /* (Word count - 1) * 100% */
      top: -300%;
    }
  }
  .habout-btn {
    background-color: #d6ac5b;
    color: #fff;
}
.habout-btn:hover {
    background-color: #355379;
    color: #fff;
}