@keyframes caption-lg-move {
    from {bottom: 0px;}
    to {bottom: 30%;}
}
@keyframes caption-md-move {
    from {top: 0px;}
    to {top: 30%;}
}
.carousel-caption {
    bottom: 1px !important;
    font-size: small;
  }
.carousel-caption-md {
    position: absolute;
    top: 30%;
    z-index: 10;
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
    color: #fff;
    animation-name: caption-md-move;
    animation-duration: 4s;
}
.caption-two-md {
    left: 50px !important;
    right: 50px !important;
}
.header-two {
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #fff;
    color: #355379 !important;
    // text-shadow: 0 0 3px #355379, 0 0 5px #355379
}
.header-two-md {
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #fff;
    color: #355379 !important;
    // text-shadow: 0 0 3px #355379, 0 0 5px #355379
}
.carousel-caption-lg {
    position: absolute;
    bottom: 30%;
    left: 20%;
    right: 20%;
    z-index: 10;
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
    color: #fff;
    animation-name: caption-lg-move;
    animation-duration: 4s;
}
.caption-btn {
    background-color: #d6ac5b;
    color: #fff;
}
.caption-btn:hover {
    background-color: #355379;
    color: #fff;
}
.caption-btn-two {
    background-color: #fff;
    color: #2f2f2f;
}
.caption-btn-two:hover {
    background-color: #2f2f2f;
    color: #fff;
}
.carousel-caption h4 {
    font-size: 20px;
}
.carousel-caption-md h3 {
    font-size: 40px;
}
.carousel-caption-lg h1 {
    font-size: 80px;
}
