/* Navbar design */
.nav-logo {
    width: 120px;
}
.tabs a {
    color: #fff;
    font-weight: bold;
}

ul .nav-item .active{
    background-color: #d6ac5b !important;
    margin-left: 2px;
    margin-right: 2px;
    color: #fff !important;
    border: none !important;
}
.tabs a:hover {
    background-color: #d6ac5b;
    margin-left: 1px;
    margin-right: 1px;
    color: #fff;
}

.nav-icon {
    display: flex;
    align-items: center;
    column-gap: 1.2rem;
    position: relative;
    margin-right: 10px;
  }
  
  .nav-icon span i {
    font-size: 1.9rem;
    cursor: pointer;
  }
  
  .nav-icon .badge {
    position: absolute;
    top: -6px;
    right: -8%;
    width: 15px;
    height: 15px;
    content: '';
    color: #fff;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.7rem;
    font-weight: 600;
    z-index: 10;
  }
  
/* Mobile Nav Design */
.mob-nav-item a {
    color: #fff;
    text-decoration-line: none;
    font-family: 'Roboto', sans-serif;
}
.mob-nav-item a:hover {
    color: #d6ac5b;
}
.mob-nav-item .active{
    color: #d6ac5b;
}
.mobile-nav li {
    color: #d6ac5b;
    text-decoration-line: none;
    font-family: 'Roboto', sans-serif;
}
.mobile-nav li:hover{
    color: #d6ac5b;
}