.hservices-list {
    list-style: none;
}

.accordion-button:not(.collapsed){
    background-color: #d6ac5b !important;
    color: #fff !important;
    outline: none !important;
}
.hservice-btn {
    background-color: #d6ac5b;
    color: #fff;
}
.hservice-btn:hover {
    background-color: #355379;
    color: #fff;
}
.collapsed {
    padding: 10px 10px 10px 10px !important;
    background-color: #fff !important;
    border: none !important;
}
#srvbtnone {
    border: none !important;
    background-color: #fff;
}
#srvbtntwo {
    border: none !important;
    background-color: #fff;
}
#srvbtnthree {
    border: none !important;
    background-color: #fff;
}
.accordion-item {
    border-color: #d6ac5b !important;
    padding-bottom: 15px !important;
    padding-top: 15px !important
}